<template>
  <div>
    <data-table :id='id' :headers='headers' :items='itemsToDisplay'>
      <template v-slot:item.status="{ item }">
        {{ item.status | toStatusDescription }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="action-btn mr-2 font-weight-bold h5" @click="editUser(item)">&#x270E;</span>
      </template>
    </data-table>
    <app-pager :totalNumberOfItems="numberOfItems" :numberOfItemsPerPage=numberOfItemsPerPage :numberOfPagers="numberOfPagers" :currentPage="currentPage" v-on:paginate="goToPage" />
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
import { mapState } from 'vuex'

import { EDIT_USER_EVENT, DISABLE_USER_EVENT } from '@/utils/constants'
import { toStatusDescription } from '@/filters/enum'

import DataTable from '@/components/ui/DataTable'
import AppPager from '@/components/ui/AppPager'

export default {
  name: 'user-list',
  components: {
    DataTable,
    AppPager
  },
  inject: ['eventBus'],
  props: {
    id: {
      type: String,
      required: false,
      default: 'userList'
    }
  },
  data: () => ({
    headers: [
      {
        name: 'id',
        title: 'user id'
      },
      {
        name: 'name',
        title: 'user name'
      },
      {
        name: 'roleName',
        title: 'role'
      },
      {
        name: 'status',
        title: 'status'
      },
      {
        name: 'actions',
        title: ''
      }
    ],
    numberOfItemsPerPage: 2,
    numberOfPagers: 7,
    currentPage: 1
  }),
  computed: {
    ...mapState({
      users: state => state.userModule.users
    }),
    numberOfItems () {
      return this.users.length
    },
    itemsToDisplay () {
      return this.users.slice((this.currentPage - 1) * this.numberOfItemsPerPage, this.numberOfItemsPerPage * this.currentPage)
    }
  },
  filters: {
    toStatusDescription
  },
  methods: {
    editUser (item) {
      this.eventBus.$emit(EDIT_USER_EVENT, item)
    },
    disableUser (item) {
      this.eventBus.$emit(DISABLE_USER_EVENT, item)
    },
    goToPage (page) {
      this.currentPage = page
    }
  }
}
</script>
