<template>
  <transition name="modal">
    <div class="modal-mask" v-show="show" @click="staticClose">
      <!--<div class="modal-container" @click.stop>-->
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <h3 v-if="title">{{ title }}</h3>
          </slot>
        </div>
        <div class="modal-body">
          <slot>
          </slot>
        </div>
        <div class="modal-footer text-right">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }

  .modal-container {
    width: 600px;
    margin: 40px auto 0;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #0066b2;
  }

  .modal-body {
    margin: 20px 0;
  }

  .text-right {
    text-align: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
</style>

<script>
import { CLOSE_MODAL_EVENT } from '@/utils/constants'

export default {
  name: 'app-modal',
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnEscape: {
      type: Boolean,
      required: false,
      default: true
    },
    static: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  inject: ['eventBus'],
  methods: {
    close () {
      this.eventBus.$emit(CLOSE_MODAL_EVENT)
    },
    staticClose (evt) {
      if (evt.target == this.$el && !this.static) {
        this.close()
      }
    }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.show && this.closeOnEscape && !this.static && e.keyCode == 27) {
        this.close()
      }
    })
  }
}
</script>
