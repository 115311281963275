import Enums from '@/utils/enums'

export const toStatusDescription = (status) => {
  status = status ? 1 : 0
  return Enums.convertToEnum(Enums.Status, status).description
}

export const toRoleRightsDescription = (rights) => {
  var rightsDescriptions = []
  for (let right in Enums.RoleRights) {
    if (rights & Enums.RoleRights[right].value) {
      rightsDescriptions.push(Enums.RoleRights[right].description)
    }
  }
  return rightsDescriptions.join(', ')
}
