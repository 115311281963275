<template>
  <div id="userView">
    <transition name="slide-fade">
      <app-alert
        v-if="alert.message"
        :type="alert.type"
        :message="alert.message"
        @alertclose="closeMessage"
      />
    </transition>
    <div class="row">
      <div class="col">
        <h4>manage application users</h4>
        <app-modal :show="showAddEditModal" :title="user.isNew ? 'add new user': 'edit user'">
          <ul class="error">
            <li v-for="error in errors" :key="error.message">{{ error.message }}</li>
          </ul>
          <form id="userForm">
            <fieldset v-bind:disabled="isSavingUser">
              <employee-autocomplete
                v-if="user.isNew"
                :items="searchedEmployees"
                id="emp_search"
                :data="user.name"
                :isShown="showAddEditModal"
                isRequired
              />
              <span v-else class="font-weight-bold">{{ user.name }}</span>
              <div class="form-group row mt-4">
                <input
                  type="checkbox"
                  class="form-control col-md-2 my-auto"
                  style="margin-left:-15px"
                  v-model="user.status"
                />
                <label class="col-md-4 mr-0 mt-2">{{ user.status | toStatusDescription }}</label>
              </div>
              <div class="form-group row mt-4">
                <label class="col-md-2 mr-0 text-left">
                  role
                  <span class="text-danger font-weight-bold">*</span>
                </label>
                <div class="col-md-8 mr-0">
                  <select
                    class="form-control"
                    id="userRole"
                    name="userRole"
                    v-model="user.roleId"
                    required
                  >
                    <option
                      v-for="role in activeRoles"
                      :value="role.id"
                      :key="role.id"
                    >{{ role.name }}</option>
                  </select>
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button
              class="btn btn-danger d-inline mr-2"
              @click.prevent="cancelAddOrEdit"
              v-bind:disabled="isSavingUser"
            >Cancel</button>
            <button
              class="btn btn-primary d-inline mr-2"
              v-bind:class="{ spin: isSavingUser }"
              @click.prevent="saveUser"
              v-bind:disabled="isSavingUser"
            >
              Submit
              <span class="spinner"></span>
            </button>
          </template>
        </app-modal>
        <div class="d-flex justify-content-end clearfix mb-4">
          <button type="button" class="btn btn-primary" @click="addUser">
            <i class="icon-expand-plus" title="add new user"></i>
          </button>
        </div>
        <div class="spinner-border text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <user-list />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.error {
  text-align: left;
  color: #ac0c0c;
  list-style: none;
}

fieldset {
  border: 0;
}

button {
  position: relative;
  transition: all 1s;
}

.spin {
  padding-left: 2.5em;
  display: block;
}

.spin .spinner {
  left: -0.6em;
  top: 0.4em;
  width: 2.5em;
  display: block;
  position: absolute;
}

/* spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* The actual spinner element is a pseudo-element */
.spin .spinner::before {
  content: "";
  width: 1.5em; /* Size of the spinner */
  height: 1.5em; /* Change as desired */
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: solid 0.35em #000; /* Thickness/color of spinner track */
  border-bottom-color: #555; /* Color of variant spinner piece */
  animation: 0.8s linear infinite spinner; /* speed of spinner */
  transform: translate(-50%, -50%);
  will-change: transform;
}

/* optional, but it will affect the size if changed */
*,
*::before,
*::after {
  box-sizing: border-box;
}

input:invalid {
  border: 1px solid red !important;
}
</style>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { toStatusDescription } from '@/filters/enum'

import {
  FETCH_USERS,
  CREATE_USER,
  UPDATE_USER,
  FETCH_ROLES
} from '@/store/action-type'
import {
  CLOSE_MODAL_EVENT,
  EMPLOYEE_SEARCH_STARTED_EVENT,
  EMPLOYEE_SELECTED_EVENT,
  EDIT_USER_EVENT
} from '@/utils/constants'
import Enums from '@/utils/enums'
import { employeeService } from '@/services'

import AppAlert from '@/components/ui/AppAlert'
import AppModal from '@/components/ui/AppModal'
import EmployeeAutocomplete from '@/components/ui/EmployeeAutocomplete'
import UserList from '@/components/user/UserList'

import AlertMixin from '@/mixins/AlertMixin'
import ErrorMixin from '@/mixins/ErrorMixin'

export default {
  name: 'UserView',
  components: {
    UserList,
    AppAlert,
    AppModal,
    EmployeeAutocomplete
  },
  mixins: [AlertMixin, ErrorMixin],
  inject: ['eventBus'],
  provide () {
    return {
      eventBus: this.eventBus
    }
  },
  data: () => ({
    user: {
      id: null,
      name: '',
      roleId: null,
      roleName: '',
      status: Enums.Status.Active.value,
      isNew: true
    },
    showAddEditModal: false,
    isSavingUser: false,
    searchedEmployees: []
  }),
  computed: {
    ...mapGetters(['activeRoles'])
  },
  watch: {
    showAddEditModal (value) {
      if (value && this.user.isNew) {
        this.$nextTick(() => {
          document.getElementById('emp_search').focus()
        })
      }
    }
  },
  methods: {
    setUser (employee) {
      this.user.name = employee.name
      this.user.id = employee.id
    },
    addUser () {
      this.user.id = null
      this.user.name = ''
      this.user.roleId = ''
      this.user.roleName = ''
      this.user.status = Enums.Status.Active.value
      this.user.isNew = true
      this.showAddEditModal = true
    },
    editUser (user) {
      this.user.id = user.id
      this.user.name = user.name
      this.user.roleId = user.roleId
      this.user.roleName = user.roleName
      this.user.status = user.status
      this.user.isNew = false
      this.showAddEditModal = true
    },
    saveUser () {
      if (this.validateAddOrEditForm()) {
        const self = this
        self.isSavingUser = true
        self.closeMessage()
        const actionType = self.user.isNew ? CREATE_USER : UPDATE_USER
        store
          .dispatch(actionType, self.user)
          .then(() => {
            self.showSuccessMessage(
              `User ${
                actionType === UPDATE_USER ? 'updated' : 'created'
              } successfully`
            )
            self.showAddEditModal = false
          })
          .catch((error) => {
            self.handleError(error)
          })
          .finally(() => {
            self.isSavingUser = false
          })
      }
    },
    cancelAddOrEdit () {
      this.showAddEditModal = false
    },
    validateAddOrEditForm () {
      this.errors.splice(0, this.errors.length)
      let form = document.querySelector('#userForm')
      form.reportValidity()
      let errors = []
      if (!this.user.id) {
        errors.push({ message: 'No user selected' })
      }
      if (!this.user.roleId) {
        errors.push({ message: 'No role selected' })
      }
      this.errors = [...errors]
      return !errors.length
    }
  },
  beforeRouteEnter (to, from, next) {
    store
      .dispatch(FETCH_USERS)
      .then(() => store.dispatch(FETCH_ROLES))
      .then(next)
      .catch((error) => {
        next((vm) => {
          vm.showErrorMessage(error)
        })
      })
  },
  filters: {
    toStatusDescription
  },
  mounted () {
    const self = this
    self.eventBus.$on(EDIT_USER_EVENT, self.editUser)
    self.eventBus.$on(CLOSE_MODAL_EVENT, () => {
      self.showAddEditModal = false
    })
    self.eventBus.$on(EMPLOYEE_SEARCH_STARTED_EVENT, (searchString) => {
      self.user.name = ''
      self.user.id = null
      self.searchedEmployees.splice(0, self.searchedEmployees.length)
      employeeService.searchEmployees(searchString).then((result) => {
        if (result.data && result.data.length) {
          self.searchedEmployees = [...result.data]
        }
      })
    })
    self.eventBus.$on(EMPLOYEE_SELECTED_EVENT, self.setUser)
  },
  beforeDestroy () {
    this.eventBus.$off(EDIT_USER_EVENT)
    this.eventBus.$off(CLOSE_MODAL_EVENT)
    this.eventBus.$off(EMPLOYEE_SEARCH_STARTED_EVENT)
    this.eventBus.$off(EMPLOYEE_SELECTED_EVENT)
  }
}
</script>
