<template>
  <!--<div id="alert-shim-wrap" v-if="message">
    <div id="alert-shim" :class="{ type }">
      <p>
        {{ message }}
      </p>
    </div>
  </div>-->

  <div
    class="alert"
    v-bind:class="{'alert-danger': type == 'error', 'alert-success' : type == 'success'}"
  >
    {{ message }}
    <button type="button" class="close" aria-label="Close" @click.stop="onClose">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
  /*max-width: 56%;*/
}

/*#alert-shim-wrap #alert-shim.error {
    background: #97002e
  }

  #alert-shim-wrap #alert-shim.success {
    background: #1a2c11
  }*/
</style>

<script>
import { ALERT_TYPE_ERROR } from '@/utils/constants'

export default {
  name: 'app-alert',
  props: {
    type: {
      type: String,
      required: false,
      default: ALERT_TYPE_ERROR
    },
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    onClose () {
      this.$emit('alertclose')
    }
  }
}
</script>
