<template>
  <div>
    <data-table :id='id' :headers='headers' :items='itemsToDisplay'>
      <template v-slot:item.status="{ item }">
        {{ item.status | toStatusDescription }}
      </template>
      <template v-slot:item.rights="{ item }">
        {{ item.rights | toRoleRightsDescription }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="action-btn mr-2 font-weight-bold h5" @click="editRole(item)">&#x270E;</span>
        <span class="action-btn font-weight-bold h5" @click="removeRole(item)">&#x2716;</span>
      </template>
    </data-table>
    <app-pager :totalNumberOfItems="numberOfItems" :numberOfItemsPerPage=numberOfItemsPerPage :numberOfPagers="numberOfPagers" :currentPage="currentPage" @paginate="goToPage" />
  </div>
</template>

<style scoped lang="scss">
  .action-btn {
    cursor: pointer
  }
</style>

<script>
import { mapState } from 'vuex'

import { EDIT_ROLE_EVENT, REMOVE_ROLE_EVENT } from '@/utils/constants'
import { toStatusDescription, toRoleRightsDescription } from '@/filters/enum'

import DataTable from '@/components/ui/DataTable'
import AppPager from '@/components/ui/AppPager'

export default {
  name: 'RoleList',
  components: {
    DataTable,
    AppPager
  },
  inject: ['eventBus'],
  props: {
    id: {
      type: String,
      required: false,
      default: 'roleList'
    }
  },
  data: () => ({
    headers: [
      {
        name: 'name',
        title: 'role name'
      },
      {
        name: 'rights',
        title: 'role rights'
      },
      {
        name: 'status',
        title: 'status'
      },
      {
        name: 'actions',
        title: ''
      }
    ],
    numberOfItemsPerPage: 2,
    numberOfPagers: 7,
    currentPage: 1
  }),
  computed: {
    ...mapState({
      roles: state => state.roleModule.roles
    }),
    numberOfItems () {
      return this.roles.length
    },
    itemsToDisplay () {
      return this.roles.slice((this.currentPage - 1) * this.numberOfItemsPerPage, this.numberOfItemsPerPage * this.currentPage)
    }
  },
  watch: {
    itemsToDisplay (value) {
      if (!value.length) {
        this.currentPage = 1
      }
    }
  },
  filters: {
    toStatusDescription,
    toRoleRightsDescription
  },
  methods: {
    editRole (item) {
      this.eventBus.$emit(EDIT_ROLE_EVENT, item)
    },
    removeRole (item) {
      this.eventBus.$emit(REMOVE_ROLE_EVENT, item)
    },
    goToPage (page) {
      this.currentPage = page
    }
  }
}
</script>
