<template>
  <div class="autocomplete">
    <!--<input type="text" v-model="search" @input="onChange" />-->
    <floating-field type="text" :id="id" label="name" v-model="search" :isRequired="isRequired"></floating-field>
    <ul class="autocomplete-results" v-if="isOpen || isLoading">
      <li class="loading" v-if="isLoading">Loading results...</li>
      <li v-for="(result, i) in results" :key="i" class="autocomplete-result" @click="setResult(result)">
        {{ result.name }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
  /*.autocomplete {
    position: relative;
    width: 130px;
  }*/

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    max-height: 120px;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

    .autocomplete-result:hover {
      background-color: #0066b2;
      color: white;
    }
</style>

<script>
import { debounce } from 'lodash'
import { EMPLOYEE_SEARCH_STARTED_EVENT, EMPLOYEE_SELECTED_EVENT } from '@/utils/constants'

import FloatingField from '@/components/ui/FloatingField'

export default {
  name: 'employee-autocomplete',
  inject: ['eventBus'],
  components: {
    FloatingField
  },
  props: {
    id: {
      type: String,
      required: false,
      default: 'emp_search'
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    data: {
      type: String,
      required: true,
      default: ''
    },
    isShown: {
      type: Boolean,
      required: false,
      default: false
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      search: '',
      results: [],
      isOpen: false,
      isLoading: false,
      isSearchMode: true,
      arrowCounter: -1
    }
  },
  methods: {
    filterResults () {
      this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    setResult (result) {
      this.isSearchMode = false
      this.search = result.name
      this.isOpen = false
      this.eventBus.$emit(EMPLOYEE_SELECTED_EVENT, result)
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    }
  },
  watch: {
    // data (value, oldValue) {
    //  if (value) {
    //    this.search = value;
    //  }
    // },
    isShown (value, oldValue) {
      if (value) {
        this.isSearchMode = false
        this.search = this.data
      }
    },
    // Once the items content changes, it means the parent component
    // provided the needed data
    items: function (value, oldValue) {
      // we want to make sure we only do this when it's an async request
      // this.results = value.map(x => {
      //  return {
      //    name: x.employeeName,
      //    id: x.userID
      //  }
      // })
      this.results = value
      this.isOpen = true
      this.isLoading = false
    },
    search () {
      if (this.isSearchMode) {
        this.onSearch()
      }
      this.isSearchMode = true
    }
  },
  created () {
    const self = this
    // self.$on("input", ((value) => {
    //  alert("Input")
    // }))
    self.onSearch = debounce(function () {
      if (self.search && self.search.length >= 4) {
        self.isLoading = true
        self.eventBus.$emit(EMPLOYEE_SEARCH_STARTED_EVENT, self.search)
      }
    }, 500)
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>
