<template>
  <div class="form-field">
    <div class="form-field__control">
      <label :for="id" class="form-field__label">{{ label }}<span v-if="isRequired" class="text-danger font-weight-bold">*</span></label>
      <textarea v-if="type == FLOATING_FIELD_TEXTAREA" :id="id" :name="id" class="form-field__textarea" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" v-bind:required="isRequired"></textarea>
      <input v-else :id="id" :name="id" v-bind:type="type" class="form-field__input" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" v-bind:required="isRequired" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  $primary-color: #0066b2;
  $animation-duration: 0.4s;

  @mixin label-active() {
    font-size: 0.75rem;
    transform: translateY(-14px);
  }

  .form-field {
    display: block;
    margin-bottom: 16px;
    &--is-active

  {
    .form-field__control

  {
    &::after

  {
    border-bottom: 2px solid $primary-color;
    transform: scaleX(150);
  }

  }

  .form-field__label {
    color: #0066b2;
    @include label-active();
  }

  }

  &--is-filled {
    .form-field__label

  {
    @include label-active();
  }

  }
  }

  .form-field__label {
    display: block;
    font-size: 1.0rem;
    font-weight: normal;
    left: 0;
    margin: 0;
    padding: 18px 12px 0;
    position: absolute;
    top: 0;
    transition: all $animation-duration;
    width: 100%;
    text-align: left;
  }

  .form-field__control {
    background: rgba(238, 238, 238, 0.3);
    /*border-radius: 8px 8px 0 0;*/
    overflow: hidden;
    position: relative;
    width: 100%;
    &::after

  {
    border-bottom: 2px solid $primary-color;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all $animation-duration;
    width: 1%;
  }

  }

  .form-field__input,
  .form-field__textarea {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #999;
    color: #333;
    display: block;
    font-size: 1.2rem;
    margin-top: 24px;
    outline: 0;
    padding: 0 12px 10px 12px;
    width: 100%;
  }

  .form-field__textarea {
    height: 150px;
  }
</style>

<script>
import { FLOATING_FIELD_TEXTINPUT, FLOATING_FIELD_TEXTAREA } from '@/utils/constants'

export default {
  name: 'floating-field',
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: FLOATING_FIELD_TEXTINPUT
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  inject: ['eventBus'],
  methods: {
    close () {
      // this.eventBus.$emit(CLOSE_MODAL)
    },
    staticClose () {
      if (!this.static) {
        this.close()
      }
    }
  },
  created () {
    this.FLOATING_FIELD_TEXTINPUT = FLOATING_FIELD_TEXTINPUT
    this.FLOATING_FIELD_TEXTAREA = FLOATING_FIELD_TEXTAREA
  },
  mounted: function () {
    const setActive = (el, active) => {
      const formField = el.parentNode.parentNode
      if (active) {
        formField.classList.add('form-field--is-active')
      } else {
        formField.classList.remove('form-field--is-active')
        el.value === ''
          ? formField.classList.remove('form-field--is-filled')
          : formField.classList.add('form-field--is-filled')
      }
    }

    [].forEach.call(
      document.querySelectorAll('.form-field__input, .form-field__textarea'),
      (el) => {
        el.onblur = () => {
          setActive(el, false)
        }
        el.onfocus = () => {
          setActive(el, true)
        }
      }
    )
  }
}
</script>
